<template>
    <ion-content class="ion-padding" scroll-y="false">
        <div class="popover-container">
            <div class="popover-title">
                Filter Items
            </div>
            <ion-list>
                <ion-radio-group v-model="filter" @ionChange="selected">
                    <ion-item>
                        <ion-label>Answered</ion-label>
                        <ion-radio slot="start" value="0"></ion-radio>
                    </ion-item>
                    <ion-item>
                        <ion-label>Unanswered Required</ion-label>
                        <ion-radio slot="start" value="1"></ion-radio>
                    </ion-item>
                    <ion-item>
                        <ion-label>All Unanswered</ion-label>
                        <ion-radio slot="start" value="2"></ion-radio>
                    </ion-item>
                    <ion-item>
                        <ion-label>All Questions</ion-label>
                        <ion-radio slot="start" value="3"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>
        </div>
    </ion-content>
</template>

<script>
import { IonContent, IonList, IonItem, IonRadio, IonRadioGroup, popoverController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FilterItems',
    components: { IonContent, IonList, IonItem, IonRadio, IonRadioGroup },
    props : {
        current_filter : {
            type: String,
        }
    },
    data () {
        return {
            filter: "0",
        }
    },
    created () {
        this.filter = this.current_filter
    },
    methods : {
        selected(){
            popoverController.dismiss(this.filter);
        }
    }
});
</script>

<style scoped lang="scss">
    .popover-title {
        color: #373636;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
    }
</style>