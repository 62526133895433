<template>
  <ion-page>    
    <ion-content class="background-image" :fullscreen="true">
        <ion-progress-bar class="progress" :value="job.progress" color="secondary"></ion-progress-bar>

        <div class="ios hydrated job-header">
            <div class="job-header-container">
                <div class="back-btn">
                    <div class="orange-circle" @click="backToHome">
                        <uil-angle-left size="25px" />
                    </div>
                </div>
                <div class="job-title">
                    <h1>{{ title }}</h1>
                    <div class="job-code">#{{ job.job_code }}</div>
                    <div class="job-date">{{ job.job_date }}</div>
                </div>
                <div class="stacked-actions">
                    <div class="orange-circle">
                        <uil-file-alt size="25px" @click="openNotesModal(job)" />
                    </div>
                    <div class="orange-circle">
                        <a :href="asset.google" target="_blank">
                            <uil-directions size="25px" />
                        </a>
                    </div>
                    <div class="orange-circle" @click="viewSections()">
                        <uil-apps size="25px" />
                    </div>
                </div>
            </div>
        </div>

        <div class="selection-selector-container">
            <swiper
                :slides-per-view="defaultSliderCount"
                :navigation="true"
                @swiper="onSwiper"
                :modules="modules"
                @slideChange="onSlideChange"
                :centered-slides="true"
                :loop="true"
                :initial-slide="startingSlide"
                ref="slider"
            >
                <swiper-slide v-for="section in sections" :key="section.id" :data-id="section.id">
                    <div class="section-container">
                        <div class="section-circle">
                            <svg
                                class="progress-ring"
                                width="120"
                                height="120">
                                <circle
                                    class="progress-ring__circle"
                                    :style="circleStyle(section)"
                                    stroke="red"
                                    stroke-width="2"
                                    fill="transparent"
                                    r="30"
                                    cx="60"
                                    cy="60">
                                </circle>
                            </svg>
                            <div class="section-name">
                                <!-- <component class="orange-icon" size="40px" :is="section.component"></component> -->
                                <component class="orange-icon" size="40px" :is="getSectionComponent(section)"></component>
                            </div>
                            <template v-if="section.tick == true">
                                <uis-check-circle size="20px" class="section-tick" />
                            </template>
                        </div>
                        <div class="section-title">
                            {{ section.name }}
                        </div>
                    </div>
                </swiper-slide>
                <template v-if="canAddSection">
                    <swiper-slide >
                        <div class="section-container" @click="viewSections">
                            <div class="section-circle">
                                <div class="orange-circle">
                                    <uil-plus size="25px" />
                                </div>
                            </div>
                            <div class="section-title">
                                Add
                            </div>
                        </div>
                    </swiper-slide>
                </template>
            </swiper>
        </div>
        <template v-if="!loading">
            <div v-if="!addMode" class="items-container">
                <div class="item-title">
                    <div></div>
                    <h2>Items</h2>
                    <div class="filter-orderby">
                        <div class="orange-circle filter" @click="openFilter($event)">
                            <uil-filter size="25px"/>
                        </div>
                    </div>
                </div>
                <template v-if="Object.keys(activeSection.items).length > 0">
                    <ion-card v-for="item in activeSection.items" :key="item.id" class="item-card">
                        <ion-card-content>
                            <div class="card-container" @click="viewItem(item)">
                                <div class="item-header">
                                    <div class="item-header-left">
                                        <div class="item-icon">
                                            <component size="60px" :is="getItemComponent(item)"></component>
                                        </div>
                                        <div class="item-title">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    
                                    <div class="open-item">
                                        <!-- <uil-file-plus-alt size="25px" /> -->
                                    </div>
                                </div>
                                <div class="item-content">
                                    <!-- <div v-if="item.images.length > 0" class="image-container"> -->
                                    <template v-if="item.images.length > 0" >
                                        <ion-grid class="taken-photos">
                                            <ion-row>
                                                <template v-for="image in item.images" :key="image.id">
                                                    <ion-col size="3">
                                                        <ion-img :src="$store.state.images.images[image.id].base64"></ion-img>
                                                        <!-- <ion-img :src="image.base64"></ion-img> -->
                                                    </ion-col>
                                                </template>
                                            </ion-row>
                                        </ion-grid>
                                    </template>

                                    <template v-if="item.description != null">
                                        <div class="description">{{ item.description }}</div>
                                    </template>
                                    <template v-else>
                                        <!-- <div class="description">There is no description for this item.</div> -->
                                    </template>

                                </div>

                                <template v-if="Object.keys(item.questions).length > 0" >
                                    <div class="question-container">
                                        <!-- <div class="question-title">
                                            Questions:
                                        </div> -->
                                        <!-- <div class="questions-example">
                                            <div class="question" v-for="question in item.questions.slice(0,2)" :key="question.id">
                                                <div class="anwswer-question"></div>
                                                <div class="text-question">
                                                    {{ question.title }}
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="more-questions-btn">
                                            <div class="text">More Questions</div>
                                            <div class="text">Questions</div>
                                            <uil-angle-right-b size="25px" />
                                        </div> -->
                                    </div>
                                    <div class="progress-container">
                                        <div class="can-submit">
                                            <div v-if="item.canSubmit" class="circle circle-complete">
                                                <uil-check size="25px" />
                                            </div>
                                        </div>

                                        <div class="progress-right">
                                            <div class="color-text">
                                                <div class="circle circle-complete">
                                                </div>
                                                <div class="text-number">{{ item.completed }}</div>
                                            </div>
                                            <div class="color-text">
                                                <div class="circle circle-recomm">
                                                </div>
                                                <div class="text-number">{{ item.not_required }}</div>
                                            </div>
                                            <div class="color-text">
                                                <div class="circle circle-required">
                                                </div>
                                                <div class="text-number">{{ item.not_complete }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="description">There are no questions for this item.</div>
                                </template>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </template>
                <template v-else>
                    <div class="no-message">There are no items for this section or your filters don't match any results.</div>
                </template>
            </div>
        </template>
    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-tabs>
                <ion-router-outlet></ion-router-outlet>
                <ion-tab-bar slot="bottom">
                    <ion-tab-button tab="additem" color="primary" @click="addItem()">
                        <uil-plus size="25px"/>
                        <ion-label>Add Item</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="notes" @click="openNotesModal(job)">
                        <uil-file-alt  size="25px"/>
                        <ion-label>Notes</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="send" @click="sendJob(job)">
                        <uil-check size="25px"/>
                        <ion-label>Send</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script>
// import { IonContent, IonPage, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';
import { IonContent, IonCard, IonPage, IonLabel, IonCardContent, IonToolbar, 
    IonFooter, IonProgressBar, IonicSwiper, modalController, IonTabBar, IonTabButton, 
    IonTabs, popoverController, IonImg, IonRow, IonGrid, IonSpinner, IonRouterOutlet, IonCol, IonButton } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { UilAngleLeft, UilFileAlt, UilDirections, UilApps, UilPlus, UilCheck, UilQuestionCircle, UilAngleRightB, UilFilter, UilSortAmountDown } from '@iconscout/vue-unicons'
import { UisCheckCircle } from '@iconscout/vue-unicons-solid'
import NotesModal from '../modals/NotesModal.vue';

import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {defineAsyncComponent} from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';

import FilterItems from '../popover/FilterItems.vue';
import helpers from '../helpers/data-handling.js';

SwiperCore.use([IonicSwiper, Navigation]);

export default {
    name: 'Home',
    components: {
        IonContent, IonPage, IonProgressBar,
        UilAngleLeft, UilFileAlt, UilDirections, UilCheck, UilApps, UilPlus, UilAngleRightB, UilQuestionCircle, UilFilter, UilSortAmountDown,
        IonTabBar, IonTabButton, IonTabs, IonCard, IonLabel, IonCardContent, IonToolbar, IonFooter,
        IonCol, IonButton, IonRow, IonGrid, Swiper, SwiperSlide, UisCheckCircle, IonImg, IonSpinner, IonRouterOutlet
    },
    data () {
        return {
            defaultSliderCount : 3,
            job_id: this.$route.params.id,
            job : {},
            asset : {},
            title : "",
            sections : {},
            activeSection : {
                items : {}
            },
            addMode : false,
            startingSlide : 0,
            swiper : null,
            loading : true,
            filter: "",
            original_items : {},
            canAddSection : false,
            orderOfSections : {},
        }
    },
    setup(){
        return {
            modules: [Navigation],
        };
    },
    methods: {
        backToHome() {
            this.$router.push({ path : "/" })
        },
        viewSections(){
            this.$router.push({ 'path' : '/sections/'+ this.job.id })
        },
        async openNotesModal(job) {
            var asset_notes = job.asset.notes;

            var surveyor_notes = "";
            if(job.surveyor_notes){
                surveyor_notes = job.surveyor_notes;
            }

            if(asset_notes == "" || asset_notes == null){
                asset_notes = "There are no asset notes for this job.";
            }
            
            const modal = await modalController
            .create({
                component: NotesModal,
                componentProps: {
                    notes : asset_notes,
                    your_notes : surveyor_notes,
                    job : job
                },
            })

            modal.onDidDismiss()
                .then((data) => {
                if(data.data != undefined){
                    job.surveyor_notes = data.data;
                    this.$store.dispatch("brightchecker/saveJob", job);
                }
            });

            return  await modal.present();
        },
        onSlideChange(e) {
            if(Object.keys(this.sections).length == e.realIndex){
                this.activeSection = {
                    items : {}
                }
                this.addMode = true;
            } else {
                this.addMode = false;
                if(this.sections != undefined){
                    for(let prop in this.orderOfSections){
                        if(this.orderOfSections[prop] == e.realIndex){
                            console.log("hello")
                            this.activeSection = this.sections[prop];
                            if(this.activeSection !== undefined){
                                this.original_items =  this.activeSection.items;
                                this.filter = "3"
                                this.filterItems("3")
                                this.$store.commit("brightchecker/SET_CURRENT_SECTION", this.activeSection);
                            }
                        }
                    }
                }
            }
        },
        circleStyle(section) {
            var circumference = 30 * 2 * Math.PI;
            var color = "#B41F1F";

            if(section.progress < 75){
                color = "#F7B900";
            } else if(section.progress < 25){
                color = "#B41F1F";
            } else {
                color = "#1D9F67";
            }

            return {
                "stroke-dasharray": `${circumference}, ${circumference}`,
                "stroke-dashoffset": section.progress_calculated,
                "stroke" : color
            }
        },
        onSwiper(swiper){
            this.swiper = swiper;
        },
        async changeSwiperSlide(){
            if(this.$route.params.section){
                var section_id = this.$route.params.section;
                this.activeSection = this.sections[section_id];
                this.swiper.slideTo(this.orderOfSections[section_id] + this.startingSlide)
            } else {
                this.activeSection = this.sections[Object.keys(this.sections)[0]];
            }
            this.original_items = this.activeSection.items;
            this.$store.commit("brightchecker/SET_CURRENT_SECTION", this.activeSection);
        },
        addItem(){
            if(this.activeSection.id){
                this.$router.push({ 'path' : '/additem/'+ this.job_id + '/section/' + this.activeSection.id })
            } else {
                this.$router.push({ 'path' : '/additem/'+ this.job_id + '/section/0' })
            }

        },
        async sendJob(job){
            this.$router.push({ 'path' : '/submit/job/'+ job.id })
        },
        viewItem(item){
            this.$store.commit("brightchecker/SET_CURRENT_ITEM", item);
            this.$router.push({ 'path' : '/job/'+this.job.id+'/section/'+this.activeSection.id+'/item/'+ item.id })
        },
        async calculateProgress(){
            var full_progress = 0;
            for(let section_prop in this.sections){
                let current_section = this.sections[section_prop];

                let total_completed = 0;
                let total_not_complete = 0;
                let sectionDone = false;
                for(let item_prop in current_section.items){
                    var completed = 0;
                    var not_required = 0;
                    var not_complete = 0;

                    var current_item = current_section.items[item_prop];

                    for(let question_prop in current_item.questions){
                        var current_question = current_item.questions[question_prop];

                        if(current_question.answer.answer != null){
                            completed++;
                            total_completed++;
                        }
                        // correct
                        if(current_question.answer.answer == null && current_question.required == 2){
                            not_required++;
                        }
                        
                        if(current_question.answer.answer == null && current_question.required == 1){
                            not_complete++;
                            total_not_complete++;
                        }

                        if(current_question.answer.answer == null && current_question.required == 0){
                            // not_complete++;
                            // total_not_complete++;

                            not_required++;
                        }
                    }
                    current_item.completed = completed;
                    current_item.not_required = not_required;
                    current_item.not_complete = not_complete;

                    if(current_item.not_complete == 0 && current_item.not_required == 0){
                        this.sections[section_prop].items[item_prop].canSubmit = true;
                        sectionDone = true;
                    } else {
                        this.sections[section_prop].items[item_prop].canSubmit = false;
                        sectionDone = false;
                    }
                }
                var progress = (total_completed / (total_not_complete + total_completed)) * 100;
                current_section.progress = progress;
                var circumference = 30 * 2 * Math.PI;
                var offset = circumference - (progress / 100 * circumference);
                current_section.progress_calculated = offset;

                if(sectionDone){
                    current_section.tick = true;
                }

                full_progress = full_progress + progress;
            }
            this.job.progress = (full_progress / expected_total)

            // this.$store.dispatch("auth/saveJob", this.job);
            var expected_total = 100 * Object.keys(this.sections).length;

            if(expected_total == full_progress){
                return true;
            } else {
                return false;
            }
        },
        async openFilter(ev){
            const popover = await popoverController
                .create({
                    component: FilterItems,
                    cssClass: 'filter-items',
                    event: ev,
                    componentProps : {
                        current_filter : this.filter
                    }
                })
            await popover.present();

            var filter = await popover.onDidDismiss();
            if(filter.data == undefined){
                this.filter = "3"
                this.filterItems("3")
            } else {
                this.filter = filter.data
                this.filterItems(filter.data)
            }
        },
        filterItems(option){
            var new_job_list = {};
            console.log("original", this.original_items)
            for(let prop in this.original_items){
                let current = this.original_items[prop];
                let add = false;
                // get all answered items
                if(option == "0" && current.not_complete == 0 && current.questions.length > 0){
                    add = true;
                }
                // get all unanswered required items
                if (option == "1" && current.not_complete > 0){
                    add = true;
                }
                if (option == "2" && (current.not_complete > 0 || current.not_required > 0)){
                    add = true;
                }
                if (option == "3"){
                    add = true;
                }
                if(add){
                    new_job_list[prop] = current
                }
            }

            this.activeSection.items = new_job_list;
        },
        getSectionComponent(section){
            var icon = section.icon_import;
            icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
            return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
        },
        getItemComponent(item){          
            var icon = item.icon_import;
            icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
            return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
        },
        setupPage(){
            this.job = this.getJob;
            if(Object.keys(this.job).length === 0){
                this.job = this.getJobs[this.job_id];
                this.$store.commit("brightchecker/SET_CURRENT_JOB", this.job);
            }
            this.asset = this.job.asset;
            this.title = helpers.makeTitle(this.asset)
            this.sections = this.job.survey.sections;
            let count = 0;
            for(let prop in this.sections){
                this.orderOfSections[prop] = count;
                count++; 
            }

            for(let prop in this.sections){
                let icon = this.sections[prop].icon_import;
                icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
               
                if(this.sections[prop].repeatable == 1){
                    this.canAddSection = true;
                }
            }
            this.bkimage = this.asset.google_static;

            this.changeSwiperSlide();
            this.calculateProgress();

            if(Object.keys(this.activeSection.items).length < 3){
                this.defaultSliderCount = Object.keys(this.activeSection.items).length;
            } else {
                this.defaultSliderCount = 3;
            }

            this.loading = false
        }
    },
    computed: {
        ...mapGetters({
            getJob: 'brightchecker/current_job',
            getJobs: 'brightchecker/jobs',
            getImages: 'images/images'
        }),
    },
    mounted() {
        // this.setupPage();

    },
    beforeUpdate() {
        this.loading = true;
    },
    updated() {
        if(this.$route.params.id != undefined){
            this.job_id = this.$route.params.id;
           
        }
        this.setupPage();
    },
}
</script>

<style scoped lang="scss">
    h1, h2 {
        text-align: center;
        margin-top: 0px;
        font-size: 20px;
        font-weight: 800;
        line-height: 30px;
    }

    .job-header {
        margin-top: 20px;
        padding: 0px 15px;
    }

    .orange-circle {
        height: 35px;
        width: 35px;
    }

    .job-header-container {
        display: flex;
        .back-btn {
            flex: 1;
            .orange-circle {
                color: black;
            }
        }
        .job-title {
            flex: 3;
        }
        .stacked-actions {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            .orange-circle {
                color: black;
                margin-bottom: 10px;
            }
        }
    }

    .progress {
        margin-top: 44px;
        height: 10px;
    }

    .job-code, .job-date {
        font-size: 16px;
        font-weight: 800;
        line-height: 19px;
        color: #373636;
        font-family: 'Lato', sans-serif;
        text-align: center;
        margin-bottom: 10px;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-wrapper {
        align-items: center;
        .swiper-slide {
            transition: all 0.3s;
            .section-circle {
                background-color: #ffffff;
                // padding: 10px;
                border-radius: 50%;
                height: 70px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 20px;
                transition: all 0.3s;
                text-align: center;
                font-size: 12px;
                box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
                position: relative;
                transition: transform 0.3s; 
                .section-name {
                    position: absolute;
                    padding: 0px 8px;
                    svg.orange-icon {
                        color: #DB9123;
                    }
                }
            }

            &.swiper-slide-active .section-circle {
                transform: scale(1.6);
            }

            &.swiper-slide-active .section-title {
                margin-top: 30px;
            }

            // &.swiper-slide-active .section-circle {
            //     height: 120px;
            //     width: 120px;
            //     font-size: 16px;
            // }

            &:not(.swiper-slide-active) {
                opacity: 0.7;
            }

            .orange-circle {
                height: 30px;
                width: 30px;
                padding: 3px;
            }
        }
    }


    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .section-title {
            font-size: 17px;
            font-weight: 800;
            line-height: 20px;
            color: #373636;
            margin-top: 10px;
            font-family: 'Raleway', sans-serif;
            transition: margin 0.3s;
            text-align: center;
            padding: 0px 10px;
        }
    }

    ion-content{
        // --background: none
    }

    .background-image {
        background-position: center !important;
        background-size: cover !important;
        background-color: rgba(255, 255, 255, 1);
        background-blend-mode: luminosity;
    }

    .swiper-container {
        padding: 0px 30px;
    }

    .selection-selector-container {
        margin-top: 30px;
    }

    .items-container {
        margin-top: 30px;
    }

    .item-card {
        border-radius: 0px;
        margin-bottom: 30px;
        --background: #ffffff;
    }

    .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-header-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 3;
        }

        .item-icon {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #DB9123;
        }

        .item-title {
            font-size: 14px;
            font-weight: 800;
            line-height: 16px;
            margin-left: 15px;
            font-family: 'Raleway', sans-serif;
        }

        .open-item {
            color: #DB9123;
            flex: 1;
            text-align: end;
        }
    }

    .item-content {
        display: flex;
        margin-top: 15px;
        flex-direction: column;
        flex-wrap: wrap;
        // img, div {
        //     flex: 50%;
        // }
        img {
            width: 50%;
            height: auto;
            object-fit: contain;
        }

        .description {
            margin-left: 10px;
        }
        // .image-container {
        //     flex: 1;
        // }

        // .text-container {
        //     flex: 3;
        //     font-size: 14px;
        //     font-weight: 400;
        //     color: #373636;
        //     text-align: end;
        // }
    }

    .no-message {
        text-align: center;
        margin-top: 20px;
        padding: 0px 30px;
    }

    .question-container {
        margin-top: 15px;
        .question-title {
            font-family: 'Raleway', sans-serif;
            font-size: 14px;
            font-weight: 800;
            line-height: 16px;
            margin-bottom: 10px;
        }
        .questions-example {
            .question {
                display: flex;
                margin-bottom: 10px;
                .anwswer-question {
                    margin: 5px;
                }
                .text-question {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
        }
        .more-questions-btn {
            box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
            padding: 8px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
            .text {
                font-size: 14px;
                font-weight: 800;
            }
        }
    }

    .progress-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 20px;
        margin-top: 5px;

        .circle {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            &.circle-complete {
                background: #1D9F67;
            }
            &.circle-recomm {
                background: #808080;
            }
            &.circle-required {
                background: #B41F1F;
            }
            svg {
                color: #ffffff;
            }
        }

        .progress-right {
            display: flex;
        }

        .color-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .text-number {
                margin: 0px 10px;
                font-size: 14px;
                font-weight: 800;
            }

            &:last-child {
                .text-number {
                    margin-right: 0px;
                }
            }
        }
    }

    .progress-ring {
        position: absolute;
    }

    .progress-ring__circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }

    ion-toolbar {
        --min-height: 50px;
    }

    ion-tab-button {
        svg {
            color: #DB9123;
        }
        --background: #172943;
        color: #ffffff;

    }

    .item-title {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin: 0px 16px;
        > * {
            flex: 1
        }
        h2 {
            margin-bottom: 0px;
        }
        .filter-orderby {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .orange-circle {
            border-radius: 50%;
            background-color: #DB9123;
            padding: 5px;
            height: 35px;
            width: 35px;
            color: #172943;
        }
    }
    
    .section-tick {
        position: absolute;
        right: 0;
        bottom: 0;
        fill: #1D9F67;
    }

</style>